<template>
  <div class="mt-8">
    <div :class="container">
      <div class="col-span-1 px-16">
        <h2 class="font-bold text-4xl text-body-4 leading-12">
          {{ data.title }}
        </h2>
        <p class="text-body-4 opacity-90 text-17 pt-4">
          {{ data.context }}
        </p>
      </div>
      <div class="col-span-1 mx-auto">
        <img :src="data.src" class="mt-4" />
      </div>
      <router-link :to="data.url">
        <button class="ml-16" :class="btn">Pokračovat</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      btn:
        "h-16 px-8 mt-12 md:mt-16 mb-16 rounded-md bg-body-3 hover:opacity-70 duration-300 text-white focus:outline-none",
      container:
        "grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 mx-16 justify-center",
    };
  },
};
</script>
