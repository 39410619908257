<template>
  <ServiceInfo :data="data" />
</template>

<script>
import ServiceInfo from "../../components/serviceInfo/ServiceInfo.vue";
import { propertyAddInfo } from "../../textual/info";
export default {
  components: {
    ServiceInfo,
  },
  computed: {
    data() {
      return propertyAddInfo;
    },
  },
};
</script>
