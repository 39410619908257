export const preparationInfo = {
  title: `Připravená nemovitost znamená až o 10% víc peněz`,
  src: "/images/Rectangle 243 (2).png",
  url: "/priprava-nemovitosti",
  context: `  Nejprve je důležité říci, že
  není důležité, co Vy prodáváte, ale co potencionální kupec chce
  koupit. To je základní pravidlo. Vy prodáváte a jediné co má být
  Vašim cílem je co nejvyšší cena za nemovitost. Ano, je to
  těžké, vy z toho bytu či domu mate plno zážitků. Prodáváte kus
  Vašeho života. Ale to kupujícího nezajímá.
  Kupujícího zajímá jen a pouze jeho vlastní sen. Jeho
  představa o tom, jak si v novém bydlení vymaluje, jaké bude mít
  záclony, lustr, nábytek... jen jeho představy rozhodují. Jeho
  peníze, chcete-li.Proto musíte připravit Váš byt nebo dům
  tak, abyste pokud možno nerušili představy kupujícího. Jak to
  správně udělat, vědí stylisté.Další částí přípravy
  nemovitosti pro prodej jsou
  kvalitní světlé fotografie a dobré video. Pokud si chcete
  nastudovat, jak máte fotografovat místnost, jistě se toho dá na
  internetu nalézt mnoho. Fotografovat jednu hodinu před západem
  slunce nebo do jedné hodiny po východu slunce. Kdy je takzvané měkké
  světlo a tak dále. Jenže
  když dva dělají totéž, není to totéž. Pokud si tedy nevěříte,
  svěřte fotografování a výrobu videa profesionálům. Ti
  Vám zajistí i 3D prohlídku, což už běžně jen tak někdo nenafotí.
Dalším důležitým důvodem, proč mít skvělé fotografie, video a
  3D prohlídku jsou samotné „prohlídky nemovitosti“.Pokud máte
  kvalitní fotografie, video a máte vytvořenou 3D prohlídku,
  budete mít několikanásobně více prohlídek.Pokud nemáte dobré
  fotografie ani video a nemáte vytvořenou 3D prohlídku, výrazně to
  sníží počet prohlídek, tedy: méně lidí u Vás doma.
Pokud si objednáte tyto profesionální služby, aplikace pro Vás
  připraví WEBovou stránku. Tu potřebujete pro fenomén této
  doby: sociální sítě. Když chcete prodávat na Facebooku,
  YouTube, atd. musíte mít kam ty potenciální zájemce
  přesměrovat. Tedy mít kvalitní WEBovou stránku.`,
};

export const lawyerInfo = {
  title: `Právní služby při prodeji nemovitosti.`,
  src: `/images/Rectangle 243 (4).png`,
  url: "/pravni-sluzby",
  context: `Pokud prodáváte nemovitost, může být právní stránka prodeje
  jednoduchá, velmi zapeklitá, nebo něco mezi tím. V tom jednoduchém
  případě můžete použít náš vzor a vše jistě proběhne dobře. Pokud
  použijete vzor na nějaký extrémně složitý prodej, kde nejde jen o
  vlastní prodej, ale o složité financování, vyrovnávání hypoték
  nebo jiných dluhů atd., nemusí to dopadnout úplně optimálně.
  Opakuji, prodej nemovitosti je prodej Vaší nejcennější věci. Pokud
  uděláte chybu, můžete přijít o peníze. A i když jsou právní služby
  většinou dost drahé, ve srovnání s cennou nemovitosti jsou
  zanedbatelné. A pokud právní služby svěříte „advokátovi“, který je
  veden v advokátní komoře, ručí za svoji práci celým svým majetkem.
  Naše aplikace Vám nabízí možnost objednat si služby u
  autorizovaných advokátů s dlouholetými zkušenostmi s prodejem
  nemovitostí.`,
};

export const advertisingInfo = {
  title: `Jak správně inzerovat`,
  src: `/images/Rectangle 243 (3).png`,
  url: "/inzerce",
  context: `Inzerování je vlastně začátek prodeje Vaší nemovitosti. V tuto
  chvíli už máte vše připravené, dokonalý inzerát, skvělý popis,
  perfektní fotky, super video i parádní 3D prohlídku. Tak do toho,
  jdeme inzerovat! Ale pozor není to zas tak jednoduché. Pokud to
  uděláte špatně, přijdete o peníze. Jde o to, že dobré inzertní
  servery jsou drahé a drahé jsou proto, že ti, kteří na nich
  inzerují úspěšně prodávají. A protože jsou tyto servery tak
  úspěšné, nové nabídky se dlouho takzvaně nahoře neudrží, rychle
  propadávají dolů. A kupující moc „hluboko“ nechodí. Takové
  inzeráty sledují zase jen realitní makléři. Jednou z možností, jak
  vrátit Vás inzerát opět nahoru je takzvané „topování“. To
  samozřejmě funguje, ale platíte. Druhá možnost je inzerovat na co
  nejvíce serverech. Na trhu je jich víc než 100. Zajímavé je, že
  první zájemci přicházejí zpravidla z těch známých jako je
  SREALITY, BAZOŠ, REALITY.IDNES, potom z těch menších, a nakonec i
  z těch regionálních. Můžete si říct, proč inzerovat na těch malých
  regionálních serverech. Protože to muže to znamenat 20-30 %
  zájemců navíc. Velmi důležité je spustit celou kampaň najednou.
  Tedy všude: na inzertních serverech, webových stránkách,
  sociálních sítích. Využít takzvaný WOW efekt.`,
};

export const strategyInfo = {
  title: `Proč je důležitá cenová strategie`,
  src: `/images/Rectangle 243 (1).png`,
  url: "/strategie",
  context: `Co znamená nadpis „Strategie a dokumentace“? Stanovení ceny je
  základem správně zvolené strategie. Je třeba si přesně a odpovědně
  odpovědět na otázku KDY potřebuji peníze a KOLIK peněz za
  nemovitost požaduji. Pokud chcete za nemovitost hodně peněz,
  musíte si často počkat. Když na peníze spěcháte musíte s cenou
  dolů. Jenže pokud stanovíte příliš nízkou cenu, přicházíte o
  peníze ihned. Ale co se stane, když stanovíte cenu vysokou? Tady
  Vám musím položit otázku. Víte, kdo sleduje nemovitosti nejvíc?
  Realitní makléři! Pro některé to je dokonce zábava, koníček,
  droga. Jenomže pozor!! Normální klient nemovitost nekoupí, protože
  je cena příliš vysoká a inzerát postupně přestává být zajímavý.
  Začíná klesat v pořadí nabídek. Důvodem je systém logiky
  vyhledávačů. Pokud lidé budou z inzerátu odcházet bude se inzerát
  propadat i když budete platit za jeho topování. V té chvíli už
  inzerát sledují jen realitní makléři. Ti si počkají až cena spadne
  na hranici, kde se jim nemovitost vyplatí koupit. Tu pak koupí,
  počkají, vše udělají správně a prodají nemovitost se ziskem. A Vy
  proděláte dvakrát. Jednak jste dostali peníze pozdě a za druhé
  jste nedostali ani tolik, kolik jste minimálně požadovali.
  Podívejte se na video. Nechte si zpracovat cenovou mapu. Zajistěte
  si potřebné dokumenty. Tedy výpis z katastru, list vlastnictví,
  nabývací doklad. Pokud tyto dokumenty máte, nahrajte si je k vaší
  nemovitosti. Pokud je nemáte, stačí pár kliknutí a stáhnete si je.
  Prodávejte jako Profesionál.`,
};

export const propertyAddInfo = {
  title: `Vítejte v aplikaci, která Vás provede prodejem vaší nemovitosti`,
  src: `/images/Rectangle 243.png`,
  url: "/pridat-nemovitost",
  context: `Prodáváte nemovitost? Chcete peníze co dostane realitní
  makléř? Realitní makléř si běžně řekne za svojí práci 4 až 6
  procent. Při současných cenách nemovitostí to může být velká
  spousta peněz. Například 6% z bytu 3+1kk v ceně 6.000.000
  Kč je 360.000 Kč. Chcete je? Připravili jsme pro Vás tuto
  aplikaci, která Vás bezpečně provede prodejem nemovitosti.
  Naleznete zde videa, ve kterých Vám
  vysvětlíme, jak prodej nemovitosti funguje. Je lepší
  stanovit cenu nemovitosti co nejvíce realisticky hned
  na poprvé, nebo si myslíte že je lepší nejdříve cenu
  pěkně natáhnout a pak pomalu slevovat? Je lepší
  inzerovat nemovitost několikrát na jednom realitním
  serveru za různé ceny, nebo je lepší inzerovat jeden inzerát
  na mnoha realitních serverech a všude za stejnou cenu?
  Stačí nemovitost „nacvakat mobilem“, nebo je správně
  nasvětlená lepší „profi“ fotka? Zajímá kupujícího vámi
  nově vykachlíčkovaná koupelna? Myslíte, že Vám
  proto zaplatí víc peněz? Víte, kdo nejvíce sleduje
  nemovitosti? Víte, kdo je realitní turista? A víte, jak se
  pozná? Odpovědí na tyto a mnoho dalších otázek najdete v naší
  aplikaci. Podívejte se na úvodní video, přidejte Vaši
  nemovitost a připravte společně s naší aplikací strategii
  prodeje. Upravte nemovitost k prodeji s našimi specialisty.
  Inzerujte na mnoha realitních serverech.
  Připravte si smlouvy. A pak s úsměvem
  prodejte nemovitost. Ptáte se, kolik zaplatíte za použití
  aplikace? Co za tuto cenu dostanete? Zaplatíte 1.000, - CZK. Za
  tuto cenu dostanete do ruky nástroj, který vás bude provádět
  prodejem Vaší nemovitosti. Na jednom místě získáte veškeré
  potřebné doklady potřebné k prodeji. Připravíte si skvělý
  inzertní materiál. Na jedno kliknutí budete inzerovat na
  nejdůležitějších realitních serverech. Získáte možnost si
  objednat profesionální služby designérů, fotografů nebo
  právníků. Bonus na závěr: peníze které běžně zůstávají makléři,
  zůstanou Vám!`,
};
